import React from "react";
import ReactMarkdown from "react-markdown";
import Imgix from "react-imgix";
import "lazysizes";

const Post = () => {
  const markdown = `> Hosting is an essential tool to establish the digital presence of your business.`;

  const blueHostLink = "https://www.bluehost.com/track/brashad/";

  return (
    <div className="flex items-center flex-col">
      <Imgix
        width={800}
        height={500}
        className="lazyload"
        src="https://rashadb.imgix.net/best_webhosting_2022.webp?ixembed=1662680315171&auto=format,compress"
        sizes="auto"
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
      />
      <h1 className="mt-20 mb-10 text-4xl text-center font-semibold w-3/4 sm:w-2/3">
        4 Best Web Hosting of 2022 for business or passive income
      </h1>

      <div className="w-3/4 mt-10 prose lg:prose-xl">
        <p className="text-lg">
          Let's say you think of starting your blog, generating a passive income
          stream for affiliate marketing hustle, online store or need a website
          for your existing business. If you want to come online and make your
          website available to the rest of the world, selecting the correct web
          hosting services is critical.
        </p>
        <ReactMarkdown children={markdown} />
        <p className="text-lg">
          The most excellent web hosting services can assist you in achieving
          your objectives. They often offer dedicated hosting accounts, cloud
          services, shared hosting plans, free web design tools, top-level
          domains and domain extensions and WordPress hosting.
        </p>
        <p className="text-lg">
          In this article, we've ranked and reviewed the best three web hosting
          providers, which are worth your time and money. We've come up with
          basic categories:
        </p>
        <ul>
          <li>Best beginner-friendly</li>
          <li>Best affordable</li>
          <li>Best green and renewable energy-based provider</li>
          <li>Best overall</li>
        </ul>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-4 w-3/4">
        <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <a href={blueHostLink}>
            <Imgix
              width={225}
              height={225}
              src="https://rashadb.imgix.net/bluehost-3.webp?ixembed=1662691113945&auto=format,compress"
              className="lazyload"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
          <div className="px-5 pb-5">
            <a href={blueHostLink}>
              <p className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                The most beginner-friendly
              </p>
            </a>
            <div className="flex items-center mt-2.5 mb-5">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                5.0
              </span>
            </div>
            <div className="flex justify-between items-center">
              <a
                href={blueHostLink}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Bluehost hosting
              </a>
            </div>
          </div>
        </div>
        <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <a href="test.html">
            <Imgix
              width={225}
              height={225}
              className="lazyload"
              src="https://rashadb.imgix.net/hostingerlogo1.webp?ixembed=1662726706315&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
          <div className="px-5 pb-5">
            <a href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=107358">
              <p className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                The most affordable web hosting
              </p>
            </a>
            <div className="flex items-center mt-2.5 mb-5">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                5.0
              </span>
            </div>
            <div className="flex justify-between items-center">
              <a
                href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=107358"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Hostinger hosting
              </a>
            </div>
          </div>
        </div>
        <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
            <Imgix
              width={225}
              height={225}
              className="lazyload"
              src="https://rashadb.imgix.net/greengeeks_thumb_225.webp?ixembed=1662726793199&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
          <div className="px-5 pb-5">
            <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
              <p className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                The greenest and eco-friendly
              </p>
            </a>
            <div className="flex items-center mt-2.5 mb-5">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                5.0
              </span>
            </div>
            <div className="flex justify-between items-center">
              <a
                href="https://www.greengeeks.com/track/viteconsulting/cp-default"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                GreenGeeks hosting
              </a>
            </div>
          </div>
        </div>
        <div className="w-full max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            <Imgix
              width={225}
              height={225}
              className="lazyload"
              src="https://rashadb.imgix.net/nexcess.webp?ixembed=1662726830774&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
          <div className="px-5 pb-5">
            <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
              <p className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Best web hosting overall
              </p>
            </a>
            <div className="flex items-center mt-2.5 mb-5">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                5.0
              </span>
            </div>
            <div className="flex justify-between items-center">
              <a
                href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Nexcess hosting
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/4 mt-10 prose lg:prose-xl">
        <p className="text-lg">
          There are hundreds of service providers to compare while choosing the
          best web hosting. And they all provide varying levels of
          functionality, performance, simplicity of use, and pricing ranges -
          not to mention varying discounts and savings at checkout. The
          following are three easy takeaways from these web hosting reviews:
        </p>
        <ul>
          <li>
            How to get the most significant discounts at the checkout and save
            the most money.
          </li>
          <li>How to choose the most acceptable host for your needs.</li>
          <li>What you or your business really needs</li>
        </ul>
        <p className="text-lg">
          We think these are the best value-for-money web hosting, whether
          you're launching a blog, materializing passive income ideas, an online
          store, or a local business website.
        </p>
        <h2 className="mt-3 mb-3">
          How to choose Best Fit for your needs Web Hosting?
        </h2>
        <p className="text-lg">
          The top web hosting services offer the best speed, security, uptime,
          one-click WordPress websites, ease of use, and integrations required.
          Bluehost, Hostinger, and GreenGeeks are our top choices. Most hosting
          firms offer generous sign-up bonuses and make it simple to get
          started. But we will touch base only on 3 of our top choices:
        </p>
        <h2 className="mt-5 mb-5">
          <a className="text-color-dark" href={blueHostLink}>
            BlueHost webhosting
          </a>
        </h2>
        <p className="text-lg">
          Bluehost is our #1 recommendation for anyone looking for a custom
          solution to their business demands. You'll find everything you need at
          Bluehost, whether you're running a website, blog, or online store.
        </p>
        <p className="text-color-dark">
          <strong>Who is it for:</strong> Beginners and those who seek
          custom-tailored plans
        </p>
        <p className="text-color-dark">
          <strong>Price range: $2.95/mo - $13.95/mo</strong>
        </p>
        <picture>
          <a href={blueHostLink}>
            <Imgix
              width={800}
              height={262}
              className="lazyload"
              src="https://rashadb.imgix.net/bluehost_price.webp?ixembed=1662726918692&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p className="text-lg mt-5">
          With a free domain, <strong>free SSL </strong>, 1-click WordPress
          install, FTP, and 24/7 customer support, Bluehost is our #1 hosting
          platform recommendation for beginners and not only. Their monthly
          rates start at $2.95, and they provide a 30-day money-back guarantee.
        </p>

        <p className="text-lg">
          They're an all-in-one platform built just for WordPress that powers
          over 2 million websites. Their user-friendly interface allows you to
          sign in, select your domain, install WordPress with a single click,
          select themes, and build your site. Here is the "Bird's Eye View" and
          comparison of their services:
        </p>
        <picture>
          <a href={blueHostLink}>
            <Imgix
              width={800}
              height={656.49}
              className="lazyload"
              src="https://rashadb.imgix.net/bluhost_pricing.webp?ixembed=1662726987202&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p className="text-lg mt-5">
          They are a very reliable option with a 99.99 percent uptime. Site
          performance will not be an issue with average load times under half a
          second. You can use a few simple plugins and your WordPress site's
          cheapest plan to get your desktop site speed to 100 percent.
        </p>
        <p className="text-lg">
          Their security and connectivity with Cloudflare - the presence of a
          content delivery network—were two of the most persuasive selling
          points for me (CDN). The CDN offers defence against numerous sources
          of DDoS attacks and erroneous traffic.
        </p>
        <picture>
          <a href={blueHostLink}>
            <Imgix
              width={800}
              height={507.27}
              className="lazyload"
              src="https://rashadb.imgix.net/bluehost_banner.webp?ixembed=1662685010761&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p className="mt-5">
          They allow you to host your web files on their global network of
          servers, and using a CDN improves site speed. The time it takes for a
          visitor's location to retrieve data from your server is shortened by
          this service.
        </p>
        <p>
          Bluehost also connects with different software via their MOJO
          Marketplace, which you can find if you're reading other web hosting
          comparisons and looking for more features. Installing software, for
          example, is simple if a customer requires shopping cart software or
          e-commerce features. And of course, they are the "official"
          WordPress.org web hosting platform.
        </p>

        <h2 className="mb-5">
          <strong>Bluehost's Advantages and Disadvantages:</strong>
        </h2>
        <h3 className="text-color-dark">Pros:</h3>
        <ul>
          <li>
            Free domains, for example, are an excellent way to get started with
            a new website.
          </li>
          <li>
            Always available and helpful customer service to assist you in
            troubleshooting any issues you may encounter.
          </li>
          <li>
            Without needing to learn code, the Bluehost Builder makes it simple
            to construct a professional-looking website.
          </li>
        </ul>
        <h3 className="text-color-dark">Cons:</h3>
        <ul>
          <li>Renewal fees are a little excessive.</li>
          <li>
            If you need to migrate your site to a new server, website migration
            fees might be costly.
          </li>
        </ul>
        <h2 className="mt-5">
          <strong>
            Thus, to get started with{" "}
            <a className="text-color-dark" href={blueHostLink}>
              BlueHost Webhosting
            </a>
            :
          </strong>
        </h2>
        <ul>
          <li>
            Visit{" "}
            <strong>
              <a className="text-color-dark" href={blueHostLink}>
                BlueHost.com
              </a>
              :
            </strong>
          </li>
          <li>Select the cheapest plan for $2.95 per month</li>
          <li>Type in your domain name or use the one you already own</li>
          <li>Enter user, billing and payment information</li>
          <li>Uncheck services you do not need and click Submit button</li>
        </ul>
        <h3>
          <strong>Hints to save money:</strong>
        </h3>
        <ul>
          <li>
            Do not select services you have no idea about or limited knowledge
            off.
          </li>
          <li>
            You save around $180 if you select longer contract terms, 36 months.
          </li>
        </ul>
        <h2 className="mt-5 mb-5 ">
          <a
            className="text-color-dark"
            href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=107358"
          >
            Hostinger webhosting
          </a>
        </h2>

        <p>
          Hostinger is ideal for individuals looking for low-cost web hosting,
          but the service is nonetheless of high quality. You get free SSL and
          migration, as well as a PHP speed increase and even 24/7 tech
          assistance. If you want to save money and build a website, Hostinger
          should be your choice.
        </p>

        <p className="text-color-dark">
          <strong>Who is it for:</strong> Those whose main goal is to save money
          and still get high-quality web hosting services.
        </p>

        <picture>
          <a href="test.html">
            <Imgix
              width={800}
              height={1099.88}
              className="lazyload"
              src="https://rashadb.imgix.net/hostinger_pricing.webp?ixembed=1662727383224&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>

        <p className="text-color-dark">
          <strong>Price range: $1.99/mo - $4.99/mo</strong>
        </p>

        <p>
          Hostinger is a fantastic hosting due to its speed and cost for
          business. They're presently providing a massive up to 80% discount on
          premium plans, which start at $1.99 per month.
        </p>
        <p>
          Developers can learn to code and experiment with PHP, FTP, cPanel, and
          MySQL without incurring additional costs by using Hostinger. You can
          learn to develop free WordPress sites while honing your abilities by
          using free hosting sites and learning PHP.
        </p>
        <picture>
          <a href="test.html">
            <Imgix
              width={800}
              height={340.88}
              className="lazyload"
              src="https://rashadb.imgix.net/cPanel-Main-Page.webp?ixembed=1662728338215&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p className="mt-5">
          For beginners, Hostinger is a terrific place to start. You may develop
          your skills while learning how to make free WordPress sites. They
          offer a multilingual (20+ language) Customer Success Team that is
          available 24 hours a day, seven days a week, and is good at live
          support in under two minutes.
        </p>
        <p>
          With a 99.9% uptime guarantee and an average page load time of fewer
          than 200 milliseconds, they are among the fastest web hosting
          services. CPanel is Hostinger's proprietary, user-friendly dashboard.
          The style is simple, just what you need when creating a website. They
          also have a big deal where you can get free SSL certificates and
          domains.
        </p>

        <p>
          With Hostinger's free WordPress site and one-click installation, you
          can launch your blog right away. Additionally, they provide Zyro, a
          website builder with a huge library of layouts.
        </p>

        <h2 className="mb-5">
          <strong>Hostinger's Advantages and Disadvantages:</strong>
        </h2>
        <h3 className="text-color-dark">Pros:</h3>
        <ul>
          <li>
            You can give various people access to different portions of your
            site using multiple FTP accounts, which is ideal for larger
            businesses.
          </li>
          <li>
            The web interface is simple and allows you to get up and running
            quickly.
          </li>
          <li>
            You can select the desired country's server, which is helpful if you
            want your site to load quickly for users in that region.
          </li>
          <li>
            You can up and run Hostinger without breaking the bank because of
            the low costs.
          </li>
        </ul>
        <h3 className="text-color-dark">Cons:</h3>
        <ul>
          <li>Customer Support could be better.</li>
        </ul>

        <h3 className="mt-5 mb-5">
          <strong>
            How to get started with{" "}
            <a
              className="text-color-dark"
              href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=107358"
            >
              Hostinger webhosting
            </a>
            :
          </strong>
        </h3>
        <picture>
          <a href="test.html">
            <Imgix
              width={800}
              height={520.14}
              className="lazyload"
              src="https://rashadb.imgix.net/hostnger_pricing1.webp?ixembed=1662728464213&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <ul>
          <li className="mt-5">
            <strong>
              Visit{" "}
              <a href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=107358">
                hostinger.com
              </a>
            </strong>
          </li>
          <li>
            Select a web hosting plan. I'd go with the one in the screenshot
            below. You save $384 in 48 months
          </li>
          <li>Create your account and enter payment details</li>
          <li>Install WordPress or choose to build your site option</li>
        </ul>

        <h2 className="mt-5 mb-5">
          <a
            href="https://www.greengeeks.com/track/viteconsulting/cp-default"
            className="text-color-dark"
          >
            GreenGeeks webhosting
          </a>
        </h2>

        <p className="mt-5">
          Is the last web hosting services provider on our list in this
          overview.
        </p>
        <p>
          It is the last web hosting services provider on our list in this
          overview. GreenGeeks is the greenest web hosting service if you care
          about the environment. You'll help the environment while having a
          superb host when you choose GreenGeeks because there's no server
          waste. Furthermore, GreekGeeks plants a tree for each hosting account
          created, which is a thoughtful gesture.
        </p>
        <p className="text-color-dark">
          <strong>You can get started with it for $2.95.</strong>
        </p>
        <p>
          GreenGeeks is a ten-year-old environmentally friendly and the greenest
          web hosting company. Their uptime is 99.95 percent, and load times are
          less than 500 milliseconds.
        </p>
        <p>
          They offer data centers in the United States, Canada, and the
          Netherlands, with specific locations in Chicago, Phoenix, Toronto,
          Montreal, and Amsterdam.
        </p>
        <p>
          If you're looking for environmentally friendly web hosting, GreekGeeks
          is your option.
        </p>
        <p>
          GreenGeeks purchases 3x times the number of wind energy credits to
          cover energy needs. The US Environmental Protection Agency has awarded
          them as Green Power Partners (EPA).
        </p>
        <picture>
          <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
            <Imgix
              width={800}
              height={117.76}
              className="lazyload"
              src="https://rashadb.imgix.net/cnet.webp?ixembed=1662728540865&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p>
          When you join up for their services, you get unlimited file storage,
          bandwidth, email accounts, access to their servers, and a free domain.
        </p>
        <p>
          And, unlike other hosts, they don't just put WHM/cPanel on a dedicated
          server and call it a day; they go much farther.
        </p>
        <p>
          They have a function called hosting account isolation setup that is
          great. Their accounts are with dedicated computing resources and a
          secure virtual file system using a VPS, container-based technique
          (Secure vFS). Secure vFS prevents unauthorized access, protecting your
          website from viruses and other harmful behaviour.
        </p>
        <picture>
          <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
            <Imgix
              width={800}
              height={876.76}
              src="https://rashadb.imgix.net/green_pricing.webp?ixembed=1663899207005&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <h3 className="mt-5 mb-5">
          <strong>
            <a
              href="https://www.greengeeks.com/track/viteconsulting/cp-default"
              className="text-color-dark"
            >
              GreenGeeks webhosting
            </a>{" "}
            core features:
          </strong>
        </h3>
        <picture>
          <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
            <Imgix
              width={800}
              height={580.21}
              className="lazyload"
              src="https://rashadb.imgix.net/green_tools.webp?ixembed=1662728639016&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <ul>
          <li className="mt-5">
            Visitors will trust your site more if it has a free SSL certificate.
          </li>
          <li>
            Unmetered Transfer - you can send and receive as much data as you
            want without incurring overage fees
          </li>
          <li>On-demand backups keep your data safe and secure at all times</li>
          <li>
            Free Dedicated IP provides you with a unique IP address that is not
            shared with any other websites, enhancing the security and speed of
            your website
          </li>
          <li>
            It's simple to get started with a WordPress site using one-click
            WordPress instals
          </li>
          <li>
            Object caching ensures that your site loads rapidly regardless of
            visitor location
          </li>
        </ul>
        <h3 className="text-color-dark">
          <strong>Price ranges: $2.95 - $10.95</strong>
        </h3>

        <p>
          As with any other web hosting provider, you pay up to 70% of the price
          if you commit to 36-months contracts.
        </p>

        <h3 className="mb-4">GreenGeeks's Advantages and Disadvantages:</h3>

        <h3 className="text-color-dark">Pros:</h3>
        <ul>
          <li>
            Due to bandwidth and resource allocation, you can have as much
            traffic as you want without worrying about overage fees.
          </li>
          <li>
            Their team offers first-rate assistance if you experience any
            problems.
          </li>
          <li>
            With their object caching system, server speed is greatly enhanced.
          </li>
        </ul>
        <h3 className="text-color-dark">Cons:</h3>
        <ul>
          <li>The deployment of software may have been made simpler</li>
        </ul>

        <h3 className="mt-5 mb-5">
          <strong>
            How to get started with{" "}
            <a
              href="https://www.greengeeks.com/track/viteconsulting/cp-default"
              className="text-color-dark"
            >
              GreenGeeks webhosting
            </a>
            :
          </strong>
        </h3>
        <ul>
          <li className="mt-5">
            <strong>
              Visit
              <a href="https://www.greengeeks.com/track/viteconsulting/cp-default">
                {" "}
                greengeeks.com
              </a>
            </strong>{" "}
            {""}
          </li>
          <li>Select preferred plan</li>
          <li>Enter a new domain or existing one</li>
          <li>Enter account and billing information</li>
          <li>Click on Create Account</li>
        </ul>

        <h2 className="mt-5 mb-5">
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            Nexcess webhosting
          </a>
        </h2>

        <p>
          For companies who wish to manage their WordPress hosting as
          effectively as possible, Nexcess is perfect. Nexcess is the best
          option for eCommerce companies using this platform (and who also wish
          to scale).
        </p>

        <p className="text-color-dark">
          <strong>You can get started with it for - $13.30</strong>
        </p>

        <p>
          You get <strong>2 months FREE</strong> on the annual package and{" "}
          <strong>30 Days Money Back</strong> Guarantee.
        </p>
        <picture>
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            <Imgix
              width={800}
              height={500}
              className="lazyload"
              src="https://rashadb.imgix.net/nexcess-logo-1.webp?ixembed=1662728713241&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <p>
          Nexcess is a fully managed WordPress hosting. It is a web hosting that
          powers over 500,000 websites. They additionally offer to host several
          different platforms, including e-commerce websites.
        </p>
        <p>
          Given that it provides all the tools necessary to manage dynamic
          sites, Nexcess is a fantastic choice for eCommerce enterprises.
          Nexcess offers 2TB of bandwidth, a free SSL certificate, automated
          picture compression, PHP 7 support and more with the most basic plan.
        </p>
        <p>
          Additionally, all of their plans include access to free WordPress
          migrations.
        </p>
        <p>
          Additionally, as Nexcess offers stencils for rapid site development,
          you don't need any specialized knowledge to create a WordPress
          website.
        </p>
        <p>
          You can always contact their customer service since they are very
          responsive. Every day of the week, WordPress specialists are reachable
          by phone or live chat.
        </p>
        <picture>
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            <Imgix
              width={800}
              height={500}
              className="lazyload"
              src="https://rashadb.imgix.net/nexcess_review.webp?ixembed=1662728764229&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>

        <h3 className="mt-5"> Services and tools</h3>

        <ul>
          <li>Advanced caching</li>
          <li>Daily backups</li>
          <li>Image lazy loading</li>
          <li>Unlimited email accounts</li>
          <li>WordPress core updates</li>
          <li>One-click staging site</li>
          <li>A built-in CDN with 22 locations</li>
          <li>Unlimited traffic</li>
          <li>Always-on security monitoring</li>
          <li>24/7/365 customer support</li>
        </ul>

        <p>
          There is also visual regression testing to ensure that system updates
          do not affect your code structure or functionality. The hosting plans
          include plugins like WP Merge, Visual Compare, iThemes Security Pro,
          TinyPNG, and Qubely Pro.
        </p>
        <p>
          Nexcess offers the most popular e-commerce and content management
          systems. These products cover the needs of both beginners and
          experienced users.
        </p>
        <picture>
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            <Imgix
              width={800}
              height={500}
              className="lazyload"
              src="https://rashadb.imgix.net/nexcess_products.webp?ixembed=1662728826502&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <h3 className="mt-5">Pricing</h3>
        <p>
          Nexcess offers seven pricing plans. These plans cover a wide range of
          business needs. Here is the plans overview:
        </p>
        <ul>
          <li>
            Spark – $13.30 per/m (1 website, 15 GB storage, 2 TB bandwidth)
          </li>
          <li>
            Maker – $55.30 per/m (up to 5 sites, 40 GB storage, 3 TB bandwidth)
          </li>
          <li>
            Designer – $76.30 per/m (up to 10 sites, 60 GB storage, 4 TB
            bandwidth)
          </li>
          <li>
            Builder – $104.30 per/m (up to 25 sites, 100 GB storage, 5 TB
            bandwidth)
          </li>
          <li>
            Producer – $209.30 per/m (up to 50 sites, 300 GB storage, 5 TB
            bandwidth)
          </li>
          <li>
            Executive – $384.30 per/m (up to 100 sites, 500 GB storage, 10 TB
            bandwidth)
          </li>
          <li>
            Enterprise – $699.30 per/m (up to 250 sites, 800 GB storage, 10 TB
            bandwidth)
          </li>
        </ul>
        <picture>
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            <Imgix
              width={800}
              height={500}
              className="lazyload"
              src="https://rashadb.imgix.net/nexcess_wordpress.webp?ixembed=1662728881787&auto=format,compress"
              sizes="auto"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
            />
          </a>
        </picture>
        <h3 className="mt-5 mb-5">Nexcess's Advantages and Disadvantages:</h3>

        <h3 className="text-color-dark">Pros:</h3>

        <ul>
          <li>
            Nexcess has an excellent dashboard for monitoring server resources
            and usage.
          </li>
          <li>
            Speed is crucial for retaining visitors, and uptime indicates how
            frequently the website will go offline.
          </li>
          <li>
            You can solve any issues that do arise with the help of prompt,
            detailed service
          </li>
        </ul>

        <h3 className="text-color-dark">Cons:</h3>
        <ul>
          <li>It would be good to have NGINX for our dedicated servers.</li>
          <li>A bit pricy.</li>
        </ul>

        <p>
          For the first six months of all pricing plans, click this link to
          receive 50% discount.
        </p>

        <h3 className="mb-5">
          {" "}
          <a href="https://nexcess.pxf.io/c/3490984/878328/12206?subId1=blog">
            Try Nexcess webhosting
          </a>
        </h3>

        <h2>SUMMARY</h2>

        <p>
          As we mentioned, there is an excellent selection of web hosting
          providers. It always comes down to the personal needs of a specific
          person or business. Over time, your business and individual needs will
          change.
        </p>
        <p>
          After some iteration and experience, you will have a better idea of
          your actual needs. You will have a better idea as a business value
          most. This will form your selection criteria for which web hosting
          provider is "Best" :).
        </p>
        <p>
          In the meantime, we've tried to help you gather basic selection
          criteria and opinions from our experience and the experience of our
          customers.
        </p>
      </div>
    </div>
  );
};

export default Post;
