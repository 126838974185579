import React from "react";
import rashad from "../assets/hostingblog/myprofileimg.webp";

const About = () => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mt-10 mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              About Me
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              Communication is key to successfull business
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img
            className="dark:hidden w-3/4"
            src={rashad}
            alt="rashad's profile img"
          />
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              To me business is not separate from day-today life though
              mainstream convince us otherwise
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              I'm a dad of 3 boys. Currently I'm running my web development
              agency as professional career.
            </p>
            <p>
              I've invested in crypto and metaverse project{" "}
              <a className="font-bold" href="https://soccercity.io/">
                SOCCERCITY
              </a>
              . It worked out well so far since{" "}
              <a
                className="font-bold"
                href="https://en.wikipedia.org/wiki/Ronaldinho"
              >
                Ronaldinho
              </a>{" "}
              also bought shares on{" "}
              <a
                className="font-bold"
                href="https://www.instagram.com/p/CS4tswJnu7K/?utm_source=ig_web_copy_link"
              >
                this project
              </a>
              .
            </p>
            <br />
            <p>
              I have extensive client side experience. This includes serving as
              Regional Commercial controller for Nokia where I was responsible
              for commercial profitability of the customer projects for over USD
              250 million dollars in sales. I also had to make sure that
              appropriate financing by EXIM banks is available for customer
              project rollout and all processes are compliant with EU
              regulations. To achieve this I had to arrange and manage resources
              such as account managers, pre-sales activities, customer contract
              commercial terms and conditions negotiations, logistics,
              procurement, customer project roll out teams and customer and
              company key stakeholders relationships management.
            </p>
            <br />
            <p>
              As a business consultant I excel in identifying, creating, and
              implementing the strategy required for my clients to reach their
              goals. Very recent example is Zedi Solutions which I helped to
              optimize their operations to achieve targeted annual sales and
              gross margin results. With my business analysis methodology I
              identified several areas of focus such as inter departmental
              communication/co-operation efficiency, developing more efficient
              daily operations, a more robust communications platform, and
              streamlined business processes. As a result of changes Zedi
              Solutions not only saved around 1.2 MUSD but eventually was sold
              to Emerson.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
