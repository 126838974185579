import React from "react";
import rashad_avatar from "../assets/hostingblog/profile_img.webp";

import banner from "../assets/hostingblog/webhosting.webp";
import bannerseo from "../assets/seo/seo.webp";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "react-router-dom";
// to be or not to be
const Posts = () => {
  return (
    <div>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
            <h2 className="mt-10 mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              My media and articles
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              We use an agile approach to test assumptions and connect with the
              needs of your audience early and often.
            </p>
          </div>
          <div className="grid gap-8 lg:grid-cols-2">
            <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center mb-5 text-gray-500">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    ></path>
                  </svg>
                  Blog Post
                </span>
                <span className="text-sm">Aug 01, 2022</span>
              </div>

              <img
                className="rounded-t-lg"
                src={banner}
                alt="4 best web hosting"
                width="900"
                height="450"
              />

              <h2 className="mt-5 mb-5 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <Link to="/post">
                  4 Best Web Hosting of 2022 for business or passive income
                </Link>
              </h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                There are hundreds of service providers to compare while
                choosing the best web hosting.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src={rashad_avatar}
                    alt="Rashad Bayram avatar"
                  />
                  <span className="font-medium dark:text-white">
                    Rashad Bayram
                  </span>
                </div>
                <Link to="/post">
                  <ListItemButton
                    sx={{
                      textAlign: "center",
                      flexDirection: "column",
                      display: "inlineFlex",
                      alignItems: "itemsCenter",
                      fontWeight: 500,
                    }}
                  >
                    <ListItemText primary="View more" />
                    <svg
                      className="ml-2 w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </ListItemButton>
                </Link>
              </div>
            </article>

            <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center mb-5 text-gray-500">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    ></path>
                  </svg>
                  Blog Post
                </span>
                <span className="text-sm">Aug 27, 2022</span>
              </div>

              <img
                className="rounded-t-lg"
                src={bannerseo}
                alt="4 best web hosting"
                width="900"
                height="450"
              />

              <h2 className="mt-5 mb-5 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                <Link to="/seo">
                  The only and best ultimate SEO checklist you will ever need
                </Link>
              </h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                This is the ultimate checklist that covers best practices and
                tasks you must be aware of to achieve SEO success.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src={rashad_avatar}
                    alt="Rashad Bayram avatar"
                  />
                  <span className="font-medium dark:text-white">
                    Rashad Bayram
                  </span>
                </div>
                <Link to="/seo">
                  <ListItemButton
                    sx={{
                      textAlign: "center",
                      flexDirection: "column",
                      display: "inlineFlex",
                      alignItems: "itemsCenter",
                      fontWeight: 500,
                    }}
                  >
                    <ListItemText primary="View more" />
                    <svg
                      className="ml-2 w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </ListItemButton>
                </Link>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Posts;
